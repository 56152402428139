#siteContainer {
  &, #siteContainer.container-fluid {
    
    padding: 15px 0 0 0;
    
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto;
    background: #fff;
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: 15px;
    font-size: 2.25em;
    font-weight: 600;
    border-bottom: $borderWidth solid $borderColor;
    text-transform: initial;
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}

.layoutContainer:not(.homeMediaSlider)
.sn-media-slider {
  .flexslider {
    .flex-viewport {
      .slides {
        .slide {
          .media-wrapper {
              a{
                  img {
                    top: 0!important;
                    height: 100%!important;
                    width: auto!important;
                    left: 0!important;
                }
            }
          }
        }
      }
    }
  }
}
